import Vue from 'vue';
import TYPES from '@/types';
import { currencyFormat } from '@/vue-app/utils/currency';
import { minValueRule, requiredRule } from '@/vue-app/utils/form-rules';

// Application
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import CalculateCustomGoalMinimumAccumulatedAmountQuery
  from '@/modules/flagship/custom-investor-goal-calculator/application/queries/calculate-custom-goal-minimum-accumulated-amount-query';
import EditEmergencyFundGoalPlanBudgetService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-budget-service';
import EditEmergencyFundGoalPlanCurrentPlanService
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/application/services/edit-emergency-fund-goal-plan-current-plan-service';

// Domain
import { BudgetEntity }
  from '@/modules/flagship/edit-plan-goals/edit-emergency-fund-goal-plan/domain/entities/budget-entity';
import {
  CustomGoalMinimumAccumulatedAmountCalculationDto,
} from '@/modules/flagship/custom-investor-goal-calculator/domain/dtos/custom-goal-minimum-accumulated-amount-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class EditEmergencyFundYourBudgetViewModel {
  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.CALCULATE_CUSTOM_GOAL_MINIMUM_ACCUMULATED_AMOUNT_QUERY)
  private readonly calculate_minimum_accumulated_amount!:
    CalculateCustomGoalMinimumAccumulatedAmountQuery;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_BUDGET_SERVICE)
  private readonly budget_service!: EditEmergencyFundGoalPlanBudgetService;

  @Inject(TYPES.EDIT_EMERGENCY_FUND_GOAL_PLAN_CURRENT_PLAN_SERVICE)
  private readonly current_plan_service!: EditEmergencyFundGoalPlanCurrentPlanService;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.goals-dashboard.edit-plan-goals.edit-emergency-fund-goal-plan.your_budget';

  readonly view: Vue;

  public constructor(view: Vue) {
    this.view = view;
  }

  timer?: NodeJS.Timer;

  your_budge = {
    monthly_income: '0',
    monthly_expenses: '0',
    monthly_savings_capacity: 0,
    new_emergency_fund: '0',
  }

  is_valid_form = false;

  is_loading = false;

  display_tooltip = false;

  slider_months_of_savings = 3;

  months_of_savings = 3;

  current_amount = 0;

  readonly max_months_to_reach_goal_range = 6;

  readonly min_months_to_reach_goal_range = 3;

  private readonly MIN_SAVINGS_CAPACITY_PERCENT = 0.10;

  input_rules = {
    monthly_income: [requiredRule, (value: string) => minValueRule(value.replace(/[^0-9.-]/g, ''), '$1.00 MXN')],
  };

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setInitialValues = async (budget_information: BudgetEntity) => {
    this.your_budge = {
      monthly_income: String(budget_information.monthly_income),
      monthly_expenses: String(budget_information.monthly_expenses),
      monthly_savings_capacity: budget_information.monthly_savings_capacity,
      new_emergency_fund: String(budget_information.new_emergency_fund),
    };
    await this.calculateNewEmergencyFund(false);
  }

  setBudgetInformation = (budget_information: BudgetEntity) => {
    this.budget_service.setBudgetInformation(budget_information);
  }

  get is_continue_btn_disabled() {
    return !this.is_valid_form || this.is_loading;
  }

  get are_expenses_greater_than_monthly_income() {
    return this.parseCurrencyToNumber(this.your_budge.monthly_expenses) > this
      .parseCurrencyToNumber(this.your_budge.monthly_income);
  }

  get is_monthly_savings_capacity_less_than_ten_percent_of_monthly_income() {
    const ten_percent_of_monthly_income = this.parseCurrencyToNumber(this.your_budge
      .monthly_income) * this.MIN_SAVINGS_CAPACITY_PERCENT;
    const monthly_savings_capacity = this.parseCurrencyToNumber(this.your_budge
      .monthly_income) - this.parseCurrencyToNumber(this.your_budge.monthly_expenses);
    return monthly_savings_capacity <= ten_percent_of_monthly_income;
  }

  get monthly_savings_capacity_message() {
    return (this.is_monthly_savings_capacity_less_than_ten_percent_of_monthly_income) ? 'suggested_monthly_savings' : 'monthly_savings_capacity';
  }

  getAmountFormatted(amount: number) {
    return currencyFormat(amount);
  }

  getBudgetInformation = () => (this.budget_service.getBudgetInformation());

  getCurrentPlantInformation = () => (this.current_plan_service.getCurrentPlanInformation());

  parseCurrencyToNumber = (currency: string) => parseFloat(currency.replace(/[^0-9.]/g, ''));

  loadInvestorProfile = async () => {
    try {
      const { net_monthly_expenses, net_monthly_income } = await this
        .get_investor_profile_query.execute();
      this.your_budge.monthly_income = String(net_monthly_income);
      this.your_budge.monthly_expenses = String(net_monthly_expenses);
      await this.calculateNewEmergencyFund(true);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.load_investor_profile'));
    }
  }

  calculateMinimumAccumulatedAmount = async () => {
    try {
      this.is_loading = true;
      const interest_rate = 0.055;
      const payload: CustomGoalMinimumAccumulatedAmountCalculationDto = {
        initial_amount: this.current_amount,
        monthly_required_amount: 0,
        fixed_time_adjusted: 1,
        interest_rate,
      };
      const {
        minimum_accumulated_amount,
      } = await this.calculate_minimum_accumulated_amount.execute(payload);
      this.your_budge.new_emergency_fund = (Math.ceil(parseFloat(minimum_accumulated_amount)))
        .toString();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.calculate_minimum_accumulated_amount'));
    } finally {
      this.is_loading = false;
    }
  }

  calculateNewEmergencyFund = async (calculate_with_months: boolean) => {
    const parsed_monthly_income = this.parseCurrencyToNumber(this.your_budge.monthly_income);
    this.your_budge.monthly_savings_capacity = parsed_monthly_income - this
      .parseCurrencyToNumber(this.your_budge.monthly_expenses);
    if (this.is_monthly_savings_capacity_less_than_ten_percent_of_monthly_income) {
      this.your_budge.monthly_savings_capacity = parsed_monthly_income * this
        .MIN_SAVINGS_CAPACITY_PERCENT;
    }
    if (calculate_with_months) {
      // eslint-disable-next-line max-len
      this.your_budge.new_emergency_fund = String(parsed_monthly_income * this.slider_months_of_savings);
      this.months_of_savings = this.slider_months_of_savings;
    } else {
      // eslint-disable-next-line max-len
      this.months_of_savings = Number((this.parseCurrencyToNumber(this.your_budge.new_emergency_fund) / parsed_monthly_income).toFixed(1));
      this.slider_months_of_savings = this.months_of_savings;
    }
    await this.validateIfNewEmergencyFundIsLessThanCurrentAmount();
    this.is_loading = false;
  }

  delay = (calculate_with_months: boolean) => {
    this.is_loading = true;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    this.timer = setTimeout(async () => {
      await this.calculateNewEmergencyFund(calculate_with_months);
    }, 2000);
  }

  validateIfNewEmergencyFundIsLessThanCurrentAmount = async () => {
    if (this.parseCurrencyToNumber(this.your_budge.new_emergency_fund) <= this.current_amount) {
      await this.calculateMinimumAccumulatedAmount();
      this.months_of_savings = 1;
      this.slider_months_of_savings = this.months_of_savings;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = () => {
    const budget = {
      monthly_income: this.parseCurrencyToNumber(this.your_budge.monthly_income),
      monthly_expenses: this.parseCurrencyToNumber(this.your_budge.monthly_expenses),
      monthly_savings_capacity: this.your_budge.monthly_savings_capacity,
      new_emergency_fund: this.parseCurrencyToNumber(this.your_budge.new_emergency_fund),
    };
    this.setBudgetInformation(budget);
    this.view.$emit('nextStep');
  }

  initialize = async () => {
    const { current_amount } = this.getCurrentPlantInformation();
    this.current_amount = this.parseCurrencyToNumber(current_amount || '0');
    const budget_information = this.getBudgetInformation();
    if (budget_information.monthly_income !== 0) {
      await this.setInitialValues(budget_information);
    } else {
      await this.loadInvestorProfile();
    }
  }
}
